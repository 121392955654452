
























import { Component, Vue } from 'vue-property-decorator';
import { appName } from '@/env';

@Component
export default class Login extends Vue {
  public valid = true;
  public username: string = '';
  public appName = appName;

}
